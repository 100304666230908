import { createContext, useContext } from 'react';
import { useLocalObservable } from 'mobx-react-lite';
import { useUserStore } from './UserStoreProvider';
import { useLayoutStore } from './LayoutStoreProvider';
import { LogItemsStore } from '../stores/LogItemsStore';

const storeContext = createContext<LogItemsStore>({} as LogItemsStore);

export const ProvideLogItemsStore = ({ children }: any) => {
  const userStore = useUserStore();
  const layoutStore = useLayoutStore();
  const store = useLocalObservable(
    () => new LogItemsStore(userStore, layoutStore)
  );

  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
};

export const useLogItemsStore = () => {
  return useContext(storeContext);
};

import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { UserRole } from 'api';
import { Link, useNavigate } from 'react-router-dom';
import AppRoutes from 'routes';
import { useUserStore } from 'providers/UserStoreProvider';
import { useLayoutStore } from 'providers/LayoutStoreProvider';
import { TabsEnum } from 'stores/LayoutStore';
import Button from 'components/button';
import { ReactComponent as LogoSvg } from 'assets/trustlog-logo.svg';
import { ReactComponent as FMLogoSvg } from 'assets/frank-muller-logo.svg';
import { ReactComponent as LogoutSvg } from 'assets/icons/logout.svg';

const MobileHeader = observer(() => {
  const navigate = useNavigate();
  const userStore = useUserStore();
  const layoutStore = useLayoutStore();

  const actionButton = useMemo(() => {
    if (
      userStore.userRole === UserRole.MANUFACTURER &&
      layoutStore.currentTab === TabsEnum.MODELS
    ) {
      return (
        <Button onClick={() => layoutStore.showCreateNewModel()}>
          Add model
        </Button>
      );
    }

    if (
      userStore.userRole === UserRole.MANUFACTURER &&
      layoutStore.currentTab === TabsEnum.LOG_ITEMS
    ) {
      return (
        <Button onClick={() => layoutStore.showCreateNewLogItems()}>
          Add items
        </Button>
      );
    }

    if (userStore.isBuyerRole && layoutStore.currentTab === TabsEnum.DEFAULT) {
      return (
        <Button
          isBuyer
          variant='transparent'
          onClick={() => navigate(AppRoutes.index.path)}
        >
          {'< Go to main'}
        </Button>
      );
    }
  }, [userStore.userData, layoutStore.currentTab]);

  const isShowBrandLogo = useMemo(
    () => !userStore.userData || userStore.isBuyerRole,
    [userStore.userData]
  );
  return (
    <div className='md:hidden flex flex-col justify-center items-center gap-y-3'>
      {isShowBrandLogo ? (
        <Link to={AppRoutes.index.path}>
          <FMLogoSvg className='w-[213px]' />
        </Link>
      ) : (
        <LogoSvg className='w-[213px]' />
      )}

      {userStore.userData && (
        <>
          <span
            className={` ${
              userStore.isBuyerRole
                ? 'text-black text-lg uppercase'
                : 'text-secondary text-2xl'
            }`}
          >
            {userStore.userData?.nickname}
          </span>
          <Button
            className={`mt-auto font-medium !justify-end !p-0 ${
              userStore.isBuyerRole ? 'text-black' : 'text-secondary'
            }`}
            variant='transparent'
            onClick={() => userStore.logoutUser()}
            isBuyer={userStore.isBuyerRole}
          >
            <LogoutSvg className='opacity-50' />
            Log out
          </Button>
        </>
      )}
      {actionButton}
    </div>
  );
});

export default MobileHeader;

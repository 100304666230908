/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse } from '../models/ApiResponse';
import type { CreateUpdateRetailerDto } from '../models/CreateUpdateRetailerDto';
import type { RetailerDto } from '../models/RetailerDto';
import type { RetailerPaginationResponse } from '../models/RetailerPaginationResponse';

import type { CancelablePromise } from 'api/util/CancelablePromise'
import { OpenAPI } from 'api/util/OpenAPI'
import { request as __request } from 'api/util/request'

export class RetailerService {

    /**
     * Create retailer
     * Create retailer
     *
     * This can only be done by Admin
     * @param login Nickname or email of user who will be linked
     * @param requestBody Retailer data
     * @returns RetailerDto Successful operation
     * @throws ApiError
     */
    public static createRetailer(
        login: string,
        requestBody: CreateUpdateRetailerDto,
    ): CancelablePromise<RetailerDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/retailer/create/user/{login}',
            path: {
                'login': login,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Input data is not valid`,
                401: `Request not authorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * List retailers
     * List retailers
     *
     * This can only be done by Admin
     * @param limit
     * @param offset
     * @returns RetailerPaginationResponse Successful operation
     * @throws ApiError
     */
    public static listRetailers(
        limit?: number,
        offset?: number,
    ): CancelablePromise<RetailerPaginationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/retailer/list',
            query: {
                'limit': limit,
                'offset': offset,
            },
            errors: {
                401: `Request not authorized`,
                403: `Forbidden`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Get retailer by user id
     * Get retailer by user id
     *
     * This can only be done by Admin
     * or authorized user linked to retailer
     * @param userId ID of user
     * @returns RetailerDto Successful operation
     * @throws ApiError
     */
    public static getRetailerByUserId(
        userId: number,
    ): CancelablePromise<RetailerDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/retailer/user/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                401: `Request not authorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Get retailer by id
     * Get retailer by id
     *
     * This can only be done by Admin
     * or authorized user linked to retailer
     * @param id ID of retailer
     * @returns RetailerDto Successful operation
     * @throws ApiError
     */
    public static getRetailerById(
        id: number,
    ): CancelablePromise<RetailerDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/retailer/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Request not authorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Update retailer
     * Update retailer
     *
     * This can only be done by Admin
     * or authorized user linked to retailer
     * @param id ID of retailer
     * @param requestBody Retailer data
     * @returns RetailerDto Successful operation
     * @throws ApiError
     */
    public static updateRetailer(
        id: number,
        requestBody: CreateUpdateRetailerDto,
    ): CancelablePromise<RetailerDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/retailer/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Request not authorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Delete retailer by id
     * Delete retailer by id
     *
     * This can only be done by Admin
     * @param id ID of retailer
     * @returns ApiResponse Successful operation
     * @throws ApiError
     */
    public static deleteRetailer(
        id: number,
    ): CancelablePromise<ApiResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/retailer/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Request not authorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Link user to retailer
     * Link user to retailer
     *
     * This can only be done by Admin
     * @param id ID of retailer
     * @param login Nickname or email of user
     * @returns ApiResponse Successful operation
     * @throws ApiError
     */
    public static linkUserToRetailer(
        id: number,
        login: string,
    ): CancelablePromise<ApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/retailer/{id}/link/user/{login}',
            path: {
                'id': id,
                'login': login,
            },
            errors: {
                401: `Request not authorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Unlink user from retailer
     * Unlink user from retailer
     *
     * This can only be done by Admin
     * @param id ID of retailer
     * @param login Nickname or email of user
     * @returns ApiResponse Successful operation
     * @throws ApiError
     */
    public static unlinkUserFromRetailer(
        id: number,
        login: string,
    ): CancelablePromise<ApiResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/retailer/{id}/link/user/{login}',
            path: {
                'id': id,
                'login': login,
            },
            errors: {
                401: `Request not authorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse } from '../models/ApiResponse';
import type { FileFormat } from '../models/FileFormat';
import type { LogRequestDetailsDto } from '../models/LogRequestDetailsDto';
import type { LogRequestDto } from '../models/LogRequestDto';
import type { LogRequestPaginationResponse } from '../models/LogRequestPaginationResponse';
import type { LogRequestPhotoType } from '../models/LogRequestPhotoType';
import type { LogRequestType } from '../models/LogRequestType';
import type { ProcessTransferAction } from '../models/ProcessTransferAction';
import type { UpdateLogRequestReq } from '../models/UpdateLogRequestReq';
import type { UploadPhotosFormDataModel } from '../models/UploadPhotosFormDataModel';

import type { CancelablePromise } from 'api/util/CancelablePromise';
import { OpenAPI } from 'api/util/OpenAPI';
import { request as __request } from 'api/util/request';

export class LogRequestService {
  /**
   * List log requests
   * List log requests
   *
   * This can only be done by Admin
   * or authorized user with role Validator
   * or authorized user linked to retailer
   * or authorized user with role Buyer
   * @param requestType
   * @param requestStates Comma-delimited (without whitespaces) list of states.
   *
   * Available values: New, Submitted, UnderReview, Rejected, Approved
   * @param retailerId
   * @param buyerUserId
   * @param ownerUserId
   * @param limit
   * @param offset
   * @returns LogRequestPaginationResponse Successful operation
   * @throws ApiError
   */
  public static listLogRequests(
    requestType: LogRequestType,
    requestStates?: string,
    retailerId?: number,
    buyerUserId?: number,
    ownerUserId?: number,
    limit?: number,
    offset?: number
  ): CancelablePromise<LogRequestPaginationResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/log_request/list',
      query: {
        requestType: requestType,
        requestStates: requestStates,
        retailerId: retailerId,
        buyerUserId: buyerUserId,
        ownerUserId: ownerUserId,
        limit: limit,
        offset: offset,
      },
      errors: {
        401: `Request not authorized`,
        403: `Forbidden`,
        500: `Unhandled Internal Application Error`,
      },
    });
  }

  /**
   * Create Transfer log request
   * Create Transfer log request
   *
   * This can only be done by Admin
   * or authorized user with role Buyer
   * @param code LogItem unique code
   * @param userId ID of user
   * @returns LogRequestDto Successful operation
   * @throws ApiError
   */
  public static createTransferLogRequest(
    code: string,
    userId: number
  ): CancelablePromise<LogRequestDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/log_request/transfer/item/{code}/to/{user_id}',
      path: {
        code: code,
        user_id: userId,
      },
      errors: {
        401: `Request not authorized`,
        403: `Forbidden`,
        404: `Not found`,
        500: `Unhandled Internal Application Error`,
      },
    });
  }

  /**
   * Process Transfer log request by owner
   * Process Transfer log request by owner
   *
   * This can only be done by Admin
   * or authorized owner
   * @param id ID of log request
   * @param action Reject or Approve
   * @returns LogRequestDto Successful operation
   * @throws ApiError
   */
  public static processTransferLogRequest(
    id: number,
    action: ProcessTransferAction
  ): CancelablePromise<LogRequestDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/log_request/transfer/{id}/process/{action}',
      path: {
        id: id,
        action: action,
      },
      errors: {
        401: `Request not authorized`,
        403: `Forbidden`,
        404: `Not found`,
        500: `Unhandled Internal Application Error`,
      },
    });
  }

  /**
   * Upload photo files to log request.
   * Upload photo files to log request.
   * If `log_request_id` was not provided, will create a new one.
   *
   * This can only be done by Admin
   * or authorized user linked to retailer
   * or authorized user with role Buyer
   * @param requestType
   * @param formData photo files: log_item_photo - watches, confirm_doc_photo - receipt
   * @param logRequestId
   * @returns LogRequestDto Successful operation
   * @throws ApiError
   */
  public static uploadPhotos(
    requestType: LogRequestType,
    formData: FormData,
    logRequestId?: number
  ): CancelablePromise<LogRequestDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/log_request/upload',
      query: {
        requestType: requestType,
        logRequestId: logRequestId,
      },
      body: formData,
      mediaType: 'multipart/form-data',
      errors: {
        400: `Input data is not valid`,
        401: `Request not authorized`,
        403: `Forbidden`,
        404: `Not found`,
        500: `Unhandled Internal Application Error`,
      },
    });
  }

  /**
   * Get log request details by id
   * Get log request details by id
   *
   * This can only be done by Admin
   * or authorized user with role Validator
   * or authorized user linked to retailer
   * or authorized user with role Buyer
   * @param id ID of log request
   * @returns LogRequestDetailsDto Successful operation
   * @throws ApiError
   */
  public static getLogRequestById(
    id: number
  ): CancelablePromise<LogRequestDetailsDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/log_request/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `Request not authorized`,
        403: `Forbidden`,
        404: `Not found`,
        500: `Unhandled Internal Application Error`,
      },
    });
  }

  /**
   * Update log request
   * Update log request
   *
   * This can only be done by Admin
   * or authorized user with role Validator
   * @param id ID of log request
   * @param requestBody Log request data for update
   * @returns LogRequestDetailsDto Successful operation
   * @throws ApiError
   */
  public static updateLogRequest(
    id: number,
    requestBody: UpdateLogRequestReq
  ): CancelablePromise<LogRequestDetailsDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v1/log_request/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Request not authorized`,
        403: `Forbidden`,
        404: `Not found`,
        500: `Unhandled Internal Application Error`,
      },
    });
  }

  /**
   * Delete log request by id
   * Delete log request by id
   *
   * This can only be done by Admin
   * or authorized user with role Validator
   * @param id ID of log request
   * @returns ApiResponse Successful operation
   * @throws ApiError
   */
  public static deleteLogRequest(id: number): CancelablePromise<ApiResponse> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v1/log_request/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `Request not authorized`,
        403: `Forbidden`,
        404: `Not found`,
        500: `Unhandled Internal Application Error`,
      },
    });
  }

  /**
   * Get log request photo by type
   * Get log request photo by type
   *
   * This can only be done by Admin
   * or authorized user with role Validator
   * or authorized user linked to retailer
   * or authorized user with role Buyer
   * @param id ID of log request
   * @param photoType Photo type
   * @param format
   * @returns any Successful operation
   * @throws ApiError
   */
  public static getLogRequestPhotoByType(
    id: number,
    photoType: LogRequestPhotoType,
    format?: FileFormat
  ): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/log_request/{id}/photo/{photo_type}',
      path: {
        id: id,
        photo_type: photoType,
      },
      query: {
        format: format,
      },
      errors: {
        401: `Request not authorized`,
        403: `Forbidden`,
        404: `Not found`,
        500: `Unhandled Internal Application Error`,
      },
    });
  }

  /**
   * Submit log request with uploaded photos (change state to Submitted)
   * Submit log request with uploaded photos (change state to Submitted)
   *
   * This can only be done by Admin
   * or authorized user linked to retailer
   * or authorized user with role Buyer
   * @param id ID of log request
   * @returns LogRequestDto Successful operation
   * @throws ApiError
   */
  public static submitLogRequest(id: number): CancelablePromise<LogRequestDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/log_request/{id}/submit',
      path: {
        id: id,
      },
      errors: {
        401: `Request not authorized`,
        403: `Forbidden`,
        404: `Not found`,
        500: `Unhandled Internal Application Error`,
      },
    });
  }
}

import { makeAutoObservable, runInAction } from 'mobx';
import fileDownload from 'js-file-download';
import {
  LogItemDto,
  LogItemService,
  LogItemsGroupDto,
  LogItemsGroupService,
} from 'api';
import { UserStore } from './UserStore';
import { LayoutStore } from './LayoutStore';

export class LogItemsStore {
  constructor(private userStore: UserStore, private layoutStore: LayoutStore) {
    makeAutoObservable(this);
  }

  private _isLogItemsLoading = false;
  private _isLogItemsGroupLoading = false;
  private _isCreateLogItemsGroupLoading = false;

  private _logItemsData: LogItemDto[] = [];
  private _ownerLogItemsData: LogItemDto[] = [];
  private _logItemsGroupData: LogItemsGroupDto[] = [];

  get isLogItemsLoading() {
    return this._isLogItemsLoading;
  }

  get isLogItemsGroupLoading() {
    return this._isLogItemsGroupLoading;
  }

  get isCreateLogItemsGroupLoading() {
    return this._isCreateLogItemsGroupLoading;
  }

  get logItemsData() {
    return this._logItemsData;
  }

  get logItemsGroupData() {
    return this._logItemsGroupData;
  }

  async getLogItems({
    logItemsGroupId,
    productModelId,
    buyerUserId,
    limit = 100,
    offset = 0,
  }: {
    logItemsGroupId?: number | undefined;
    productModelId?: number | undefined;
    buyerUserId?: number | undefined;
    limit?: number | undefined;
    offset?: number | undefined;
  }) {
    runInAction(() => {
      this._isLogItemsLoading = true;
    });

    try {
      const { items } = await LogItemService.listLogItems(
        logItemsGroupId,
        productModelId,
        buyerUserId,
        limit,
        offset
      );
      runInAction(() => {
        this._logItemsData = items;
      });
    } catch (e) {
      console.error('LogItemsStore getLogItems', e);
    } finally {
      runInAction(() => {
        this._isLogItemsLoading = false;
      });
    }
  }

  async getLogItemByCode(logItemCode: string) {
    runInAction(() => {
      this._isLogItemsLoading = true;
    });

    try {
      const details = await LogItemService.getLogItemByCode(logItemCode);
      runInAction(() => {
        this._isLogItemsLoading = false;
      });
      return details;
    } catch (e) {
      console.error('LogItemsStore getLogItems', e);
    } finally {
      runInAction(() => {
        this._isLogItemsLoading = false;
      });
    }
  }

  async downloadLogItemsCsv({
    logItemsGroupId,
    productModelId,
  }: {
    logItemsGroupId?: number | undefined;
    productModelId?: number | undefined;
  }) {
    try {
      const result = await LogItemService.downloadLogItemsCsv(
        logItemsGroupId,
        productModelId
      );
      fileDownload(result, 'code.csv');
    } catch (e) {
      console.error('LogItemsStore getLogItemsCsv', e);
    }
  }

  clearLogItemsData() {
    runInAction(() => {
      this._logItemsData = [];
    });
  }

  async getLogItemsGroup(limit = 100, offset = 0) {
    if (!this.userStore.manufacturerData?.id) return;

    runInAction(() => {
      this._isLogItemsGroupLoading = true;
    });

    try {
      const { items } = await LogItemsGroupService.listLogItemsGroups(
        this.userStore.manufacturerData.id,
        limit,
        offset
      );
      runInAction(() => {
        this._logItemsGroupData = items;
      });
    } catch (e) {
      console.error('LogItemsStore getLogItemsGroup', e);
    } finally {
      runInAction(() => {
        this._isLogItemsGroupLoading = false;
      });
    }
  }

  async createLogItemsGroup(itemsCount: number) {
    if (!this.userStore.manufacturerData?.id) return;
    try {
      runInAction(() => {
        this._isCreateLogItemsGroupLoading = true;
      });
      await LogItemsGroupService.createLogItemsGroup(
        this.userStore.manufacturerData?.id,
        { itemsCount }
      );
      await this.getLogItemsGroup();
    } catch {
    } finally {
      runInAction(() => {
        this._isCreateLogItemsGroupLoading = false;
      });
    }
  }
}

import { ButtonHTMLAttributes, DetailedHTMLProps, useMemo } from 'react';
import { ReactComponent as LoaderSvg } from 'assets/icons/loader.svg';

type ButtonProps = {
  variant?: 'secondary' | 'white' | 'transparent';
  isBuyer?: boolean;
  fullWidth?: boolean;
  isLoading?: boolean;
} & DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const Button = ({
  children,
  variant,
  isBuyer,
  fullWidth,
  isLoading,
  className,
  disabled,
  ...domProps
}: ButtonProps) => {
  if (fullWidth) className += ' w-full';

  const btnClass = useMemo(() => {
    switch (variant) {
      case 'secondary':
        return isBuyer
          ? 'bg-transparent text-black border border-black rounded-none tracking-widest'
          : 'rounded-2xl bg-light text-primary hover:bg-stroke-lighter focus:bg-stroke-light';
      case 'white':
        return 'bg-white text-primary';
      case 'transparent':
        return isBuyer
          ? 'bg-transparent text-black uppercase tracking-widest'
          : 'text-secondary bg-transparent';
      default:
        return isBuyer
          ? 'bg-black text-white rounded-none tracking-widest'
          : 'rounded-2xl bg-blue text-white hover:bg-button-blue-hover focus:bg-button-blue-focus';
    }
  }, [variant]);

  return (
    <button
      {...domProps}
      className={`flex h-fit gap-2.5 items-center justify-center py-3 px-6 text-lg font-semibold ${btnClass} ${
        className ?? ''
      } ${disabled ? ' opacity-30' : ''} ${
        isBuyer ? ' uppercase text-sm' : ''
      }`}
      disabled={isLoading || disabled}
    >
      {isLoading ? <LoaderSvg className='animate-spin w-7 h-7' /> : children}
    </button>
  );
};
export default Button;

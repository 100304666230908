import { makeAutoObservable, runInAction } from 'mobx';
import AddModelModal from 'components/add-model-modal';
import AddLogItemsModal from 'components/add-log-items-modal';

export enum TabsEnum {
  DEFAULT = 'DEFAULT',
  MODELS = 'MODELS',
  LOG_ITEMS = 'LOG_ITEMS',
  RETAILER_REQUESTS = 'RETAILER_REQUESTS',
  BUYER_REQUESTS = 'BUYER_REQUESTS',
  MY_ITEMS = 'MY_ITEMS',
  OWNERSHIP_REQUESTS = 'OWNERSHIP_REQUESTS',
}

type ContentModalProps = {
  show: boolean;
  content: JSX.Element;
  size: 'sm' | 'md' | 'lg';
};

export class LayoutStore {
  constructor() {
    makeAutoObservable(this);
  }

  contentModal: ContentModalProps = {
    show: false,
    content: <></>,
    size: 'sm',
  };

  private _currentTab: typeof TabsEnum[keyof typeof TabsEnum] =
    TabsEnum.DEFAULT;

  get currentTab() {
    return this._currentTab;
  }

  changeTab(newTab: typeof TabsEnum[keyof typeof TabsEnum]) {
    runInAction(() => {
      this._currentTab = newTab;
    });
  }

  showContentModal(content: JSX.Element, size: 'sm' | 'md') {
    runInAction(() => {
      this.contentModal = {
        show: true,
        content: content,
        size: size,
      };
    });

    document.body.classList.add('no-scroll');
  }

  hideContentModal() {
    runInAction(() => {
      this.contentModal = {
        show: false,
        content: <></>,
        size: 'sm',
      };
    });

    document.body.classList.remove('no-scroll');
  }

  showCreateNewModel() {
    this.showContentModal(<AddModelModal />, 'md');
  }

  showCreateNewLogItems() {
    this.showContentModal(<AddLogItemsModal />, 'md');
  }
}

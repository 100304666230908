import { makeAutoObservable, runInAction } from 'mobx';
import {
  LoginRequest,
  ManufacturerDto,
  ManufacturerService,
  RegisterRequest,
  RetailerDto,
  RetailerService,
  UserDto,
  UserRole,
  UserService,
} from 'api';
import { axiosInstance } from 'api/util/request';

const SESSION_STORAGE_KEY = 'token';

export class UserStore {
  constructor() {
    makeAutoObservable(this);
  }

  private _isLoading = false;
  private _userData?: UserDto;
  private _manufacturerData?: ManufacturerDto;
  private _retailerData?: RetailerDto;
  private _authToken = localStorage.getItem(SESSION_STORAGE_KEY) || undefined;
  private _isEmailSent = false;

  get isLoading() {
    return this._isLoading;
  }

  get userData() {
    return this._userData;
  }

  get manufacturerData() {
    return this._manufacturerData;
  }

  get retailerData() {
    return this._retailerData;
  }

  get userRole() {
    return this._userData?.role;
  }

  get isBuyerRole() {
    return this._userData?.role === UserRole.BUYER;
  }

  get authToken() {
    return this._authToken;
  }

  get isEmailSent() {
    return this._isEmailSent;
  }

  async getManufacturerAssignedToUser(userId: number) {
    try {
      return await ManufacturerService.getManufacturerByUserId(userId);
    } catch {
      return undefined;
    }
  }

  async getRetailerAssignedToUser(userId: number) {
    try {
      return await RetailerService.getRetailerByUserId(userId);
    } catch {
      return undefined;
    }
  }

  async authUser(credentials?: LoginRequest) {
    runInAction(() => {
      this._isLoading = true;
    });

    try {
      let accessToken = this.authToken || undefined;
      let userResponse: UserDto | undefined;
      let manufacturerResponse: ManufacturerDto | undefined;
      let retailerResponse: RetailerDto | undefined;

      if (credentials) {
        const { accessToken: token } = await UserService.loginUser(credentials);
        accessToken = token;
      }

      if (accessToken) {
        this.setAuthToken(accessToken);
        userResponse = await UserService.getMyUser();
        manufacturerResponse = await this.getManufacturerAssignedToUser(
          userResponse.id
        );
        retailerResponse = await this.getRetailerAssignedToUser(
          userResponse.id
        );
      }

      runInAction(() => {
        this._userData = userResponse;
        this._manufacturerData = manufacturerResponse;
        this._retailerData = retailerResponse;
        this._authToken = accessToken;
      });
    } catch (e) {
      console.error(e);
    } finally {
      runInAction(() => {
        this._isLoading = false;
      });
    }
  }

  async registerUser(credentials: RegisterRequest) {
    runInAction(() => {
      this._isLoading = true;
      this._isEmailSent = false;
    });

    try {
      await UserService.registerUser(credentials);
      runInAction(() => {
        this._isEmailSent = true;
      });
    } catch (e) {
      console.error(e);
    } finally {
      runInAction(() => {
        this._isLoading = false;
      });
    }
  }

  async logoutUser() {
    this.deleteAuthToken();
    runInAction(() => {
      this._userData = undefined;
      this._retailerData = undefined;
      this._manufacturerData = undefined;
    });
  }

  private setAuthToken(accessToken: string | null) {
    if (!accessToken) return;
    localStorage.setItem(SESSION_STORAGE_KEY, accessToken);
    axiosInstance.defaults.headers.common = {
      Authorization: `Bearer ${accessToken}`,
    };
  }

  private deleteAuthToken() {
    localStorage.removeItem(SESSION_STORAGE_KEY);
    axiosInstance.defaults.headers.common = {
      Authorization: undefined,
    };
    runInAction(() => {
      this._authToken = undefined;
    });
  }
}

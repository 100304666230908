import axios from 'axios';
import * as qs from 'qs';

import type { ApiRequestOptions } from './ApiRequestOptions';
import { CancelablePromise } from './CancelablePromise';
import type { OpenAPIConfig } from './OpenAPI';

export const axiosInstance = axios.create({
  // Your custom Axios instance config
});

export const request = <T>(
  config: OpenAPIConfig,
  options: ApiRequestOptions
): CancelablePromise<T> =>
  new CancelablePromise((resolve, reject, onCancel) => {
    // interpolates url of format /collection/{name}/{id} into /collection/someName/1234
    const interpolatedUrl = Object.entries(options.path || {}).reduce(
      (acc, [name, value]) => acc.replace(`{${name}}`, value),
      options.url
    );

    const query = qs.stringify(options.query || {}, {
      // TODO rm later
      encode: false,
    });

    const fullUrl = `${config.BASE}${interpolatedUrl}${
      query ? `?${query}` : ''
    }`;

    const source = axios.CancelToken.source();
    onCancel(() => source.cancel('The user aborted a request.'));

    axiosInstance
      .request({
        url: fullUrl,
        data: options.body,
        method: options.method,
        cancelToken: source.token,
      })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch((error) => {
        reject(error);
      });
  });

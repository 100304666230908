export class StaticDataStore {
  get urls() {
    return {
      socials: {
        twitter: 'https://twitter.com',
        discord: 'https://discord.gg',
        telegram: 'https://t.me',
        instagram: 'https://instagram.com',
      },
    };
  }
}

const AppRoutes = {
  index: {
    path: '/',
  },
  management: {
    path: '/trustlog-management-system',
  },
  public: {
    path: '/:manufacturerSlug/:logItemCode',
  },
  emailConfirm: {
    path: '/email-confirm',
  },
};

export default AppRoutes;

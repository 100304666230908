/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse } from '../models/ApiResponse';
import type { CreateUpdateProductModelDto } from '../models/CreateUpdateProductModelDto';
import type { ProductModelDto } from '../models/ProductModelDto';
import type { ProductModelPaginationResponse } from '../models/ProductModelPaginationResponse';

import type { CancelablePromise } from 'api/util/CancelablePromise'
import { OpenAPI } from 'api/util/OpenAPI'
import { request as __request } from 'api/util/request'

export class ProductModelService {

    /**
     * List product models
     * List product models
     *
     * This can only be done by Admin
     * or authorized user linked to manufacturer or retailer
     * @param manufacturerId
     * @param retailerId
     * @param search
     * @param limit
     * @param offset
     * @returns ProductModelPaginationResponse Successful operation
     * @throws ApiError
     */
    public static listProductModels(
        manufacturerId?: number,
        retailerId?: number,
        search?: string,
        limit?: number,
        offset?: number,
    ): CancelablePromise<ProductModelPaginationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/product_model/list',
            query: {
                'manufacturerId': manufacturerId,
                'retailerId': retailerId,
                'search': search,
                'limit': limit,
                'offset': offset,
            },
            errors: {
                401: `Request not authorized`,
                403: `Forbidden`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Create product model
     * Create product model
     *
     * This can only be done by Admin
     * or authorized user linked to manufacturer
     * @param manufacturerId ID of manufacturer
     * @param requestBody Product model data
     * @returns ProductModelDto Successful operation
     * @throws ApiError
     */
    public static createProductModel(
        manufacturerId: number,
        requestBody: CreateUpdateProductModelDto,
    ): CancelablePromise<ProductModelDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/product_model/manufacturer/{manufacturer_id}',
            path: {
                'manufacturer_id': manufacturerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Input data is not valid`,
                401: `Request not authorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Get product model by id
     * Get product model by id
     * @param id ID of product model
     * @returns ProductModelDto Successful operation
     * @throws ApiError
     */
    public static getProductModelById(
        id: number,
    ): CancelablePromise<ProductModelDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/product_model/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `Not found`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Update product model
     * Update product model
     *
     * This can only be done by Admin
     * or authorized user linked to manufacturer
     * @param id ID of product model
     * @param requestBody Product model data
     * @returns ProductModelDto Successful operation
     * @throws ApiError
     */
    public static updateProductModel(
        id: number,
        requestBody: CreateUpdateProductModelDto,
    ): CancelablePromise<ProductModelDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/product_model/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Request not authorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Delete product model by id
     * Delete product model by id
     *
     * This can only be done by Admin
     * or authorized user linked to manufacturer
     * @param id ID of product model
     * @returns ApiResponse Successful operation
     * @throws ApiError
     */
    public static deleteProductModel(
        id: number,
    ): CancelablePromise<ApiResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/product_model/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Request not authorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

}

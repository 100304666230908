import { FormEvent, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useUserStore } from 'providers/UserStoreProvider';
import Button from 'components/button';
import Input from 'components/input';

const Registration = observer(({ isBuyer }: { isBuyer?: boolean }) => {
  const userStore = useUserStore();
  const [email, setEmail] = useState('');
  const [nickname, setNickname] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const formRef = useRef<HTMLFormElement>(null);

  const isFormValid = useMemo(
    () => formRef?.current?.checkValidity() || false,
    [email, nickname, phone, password, formRef]
  );

  const handleSubmit = (event: FormEvent<HTMLFormElement> | undefined) => {
    event?.preventDefault();
    if (isFormValid && !userStore.isLoading)
      userStore.registerUser({ email, nickname, phone, password });
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit} className='space-y-3'>
      <Input
        required
        type='email'
        value={email}
        label='E-mail'
        onChange={({ currentTarget }) => setEmail(currentTarget.value.trim())}
      />
      <Input
        required
        type='text'
        value={nickname}
        label='Nickname'
        onChange={({ currentTarget }) =>
          setNickname(currentTarget.value.trim())
        }
      />
      <Input
        required
        type='tel'
        value={phone}
        label='Phone'
        onChange={({ currentTarget }) => setPhone(currentTarget.value.trim())}
      />
      <Input
        required
        type='password'
        value={password}
        label='Password'
        onChange={({ currentTarget }) =>
          setPassword(currentTarget.value.trim())
        }
      />
      <Button
        type='submit'
        className='!mt-8 !text-lg'
        isLoading={userStore.isLoading}
        fullWidth
        isBuyer={isBuyer}
      >
        Sign up
      </Button>
      {userStore.isEmailSent && (
        <span className='block text-xl text-center text-black mb-6'>
          We've sent an email to your email address
        </span>
      )}
    </form>
  );
});

export default Registration;

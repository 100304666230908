/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse } from '../models/ApiResponse';
import type { CreateUpdateManufacturerDto } from '../models/CreateUpdateManufacturerDto';
import type { ManufacturerDto } from '../models/ManufacturerDto';
import type { ManufacturerPaginationResponse } from '../models/ManufacturerPaginationResponse';

import type { CancelablePromise } from 'api/util/CancelablePromise'
import { OpenAPI } from 'api/util/OpenAPI'
import { request as __request } from 'api/util/request'

export class ManufacturerService {

    /**
     * Create manufacturer
     * Create manufacturer
     *
     * This can only be done by Admin
     * @param login Nickname or email of user who will be linked
     * @param requestBody Manufacturer data
     * @returns ManufacturerDto Successful operation
     * @throws ApiError
     */
    public static createManufacturer(
        login: string,
        requestBody: CreateUpdateManufacturerDto,
    ): CancelablePromise<ManufacturerDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/manufacturer/create/user/{login}',
            path: {
                'login': login,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Input data is not valid`,
                401: `Request not authorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * List manufacturers
     * List manufacturers
     *
     * This can only be done by Admin
     * @param limit
     * @param offset
     * @returns ManufacturerPaginationResponse Successful operation
     * @throws ApiError
     */
    public static listManufacturers(
        limit?: number,
        offset?: number,
    ): CancelablePromise<ManufacturerPaginationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/manufacturer/list',
            query: {
                'limit': limit,
                'offset': offset,
            },
            errors: {
                401: `Request not authorized`,
                403: `Forbidden`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Get manufacturer by user id
     * Get manufacturer by user id
     *
     * This can only be done by Admin
     * or authorized user linked to manufacturer
     * @param userId ID of user
     * @returns ManufacturerDto Successful operation
     * @throws ApiError
     */
    public static getManufacturerByUserId(
        userId: number,
    ): CancelablePromise<ManufacturerDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/manufacturer/user/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                401: `Request not authorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Get manufacturer by id
     * Get manufacturer by id
     *
     * This can only be done by Admin
     * or authorized user linked to manufacturer
     * @param id ID of manufacturer
     * @returns ManufacturerDto Successful operation
     * @throws ApiError
     */
    public static getManufacturerById(
        id: number,
    ): CancelablePromise<ManufacturerDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/manufacturer/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Request not authorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Update manufacturer
     * Update manufacturer
     *
     * This can only be done by Admin
     * or authorized user linked to manufacturer
     * @param id ID of manufacturer
     * @param requestBody Manufacturer data
     * @returns ManufacturerDto Successful operation
     * @throws ApiError
     */
    public static updateManufacturer(
        id: number,
        requestBody: CreateUpdateManufacturerDto,
    ): CancelablePromise<ManufacturerDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/manufacturer/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Request not authorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Delete manufacturer by id
     * Delete manufacturer by id
     *
     * This can only be done by Admin
     * @param id ID of manufacturer
     * @returns ApiResponse Successful operation
     * @throws ApiError
     */
    public static deleteManufacturer(
        id: number,
    ): CancelablePromise<ApiResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/manufacturer/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Request not authorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Link user to manufacturer
     * Link user to manufacturer
     *
     * This can only be done by Admin
     * @param id ID of manufacturer
     * @param login Nickname or email of user
     * @returns ApiResponse Successful operation
     * @throws ApiError
     */
    public static linkUserToManufacturer(
        id: number,
        login: string,
    ): CancelablePromise<ApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/manufacturer/{id}/link/user/{login}',
            path: {
                'id': id,
                'login': login,
            },
            errors: {
                401: `Request not authorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Unlink user from manufacturer
     * Unlink user from manufacturer
     *
     * This can only be done by Admin
     * @param id ID of manufacturer
     * @param login Nickname or email of user
     * @returns ApiResponse Successful operation
     * @throws ApiError
     */
    public static unlinkUserFromManufacturer(
        id: number,
        login: string,
    ): CancelablePromise<ApiResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/v1/manufacturer/{id}/link/user/{login}',
            path: {
                'id': id,
                'login': login,
            },
            errors: {
                401: `Request not authorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

}

import { createContext, useContext } from 'react';
import { useLocalObservable } from 'mobx-react-lite';
import { LogRequestsStore } from 'stores/LogRequestsStore';
import { useUserStore } from './UserStoreProvider';

const storeContext = createContext<LogRequestsStore>({} as LogRequestsStore);

export const ProvideLogRequestsStore = ({ children }: any) => {
  const userStore = useUserStore();
  const store = useLocalObservable(() => new LogRequestsStore(userStore));

  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
};

export const useLogRequestsStore = () => {
  return useContext(storeContext);
};

import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { UserRole } from 'api';
import AppRoutes from 'routes';
import { TabsEnum } from 'stores/LayoutStore';
import { useUserStore } from 'providers/UserStoreProvider';
import { useLayoutStore } from 'providers/LayoutStoreProvider';
import { useLogRequestsStore } from 'providers/LogRequestsStoreProvider';
import { ReactComponent as LogoutSvg } from 'assets/icons/logout.svg';
import Button from 'components/button';

const RightSidebar = observer(() => {
  const navigate = useNavigate();
  const userStore = useUserStore();
  const layoutStore = useLayoutStore();
  const logRequestsStore = useLogRequestsStore();

  const actionButton = useMemo(() => {
    if (
      userStore.userRole === UserRole.MANUFACTURER &&
      layoutStore.currentTab === TabsEnum.MODELS
    ) {
      return (
        <Button onClick={() => layoutStore.showCreateNewModel()}>
          Add model
        </Button>
      );
    }

    if (
      userStore.userRole === UserRole.MANUFACTURER &&
      layoutStore.currentTab === TabsEnum.LOG_ITEMS
    ) {
      return (
        <Button onClick={() => layoutStore.showCreateNewLogItems()}>
          Add items
        </Button>
      );
    }

    if (userStore.isBuyerRole && !logRequestsStore.selectedLogItemCode) {
      const isStandaloneScreen = layoutStore.currentTab === TabsEnum.DEFAULT;
      return (
        <Button
          isBuyer
          onClick={() =>
            !isStandaloneScreen
              ? logRequestsStore.setIsRegisteringNewModel(true)
              : navigate(AppRoutes.index.path)
          }
        >
          {!isStandaloneScreen ? <>Register&nbsp;ownership</> : 'Go to main'}
        </Button>
      );
    }
  }, [
    userStore.userData,
    layoutStore.currentTab,
    logRequestsStore.selectedLogItemCode,
  ]);

  return (
    <div className='hidden md:flex flex-col justify-between fixed h-screen p-8 w-full max-w-[275px] right-0'>
      {actionButton}
      {!!userStore.userData && (
        <Button
          className={`mt-auto font-medium !justify-end !p-0 ${
            userStore.isBuyerRole ? 'text-black' : 'text-secondary'
          }`}
          variant='transparent'
          onClick={() => userStore.logoutUser()}
          isBuyer={userStore.isBuyerRole}
        >
          <LogoutSvg className='opacity-50' />
          Log out
        </Button>
      )}
    </div>
  );
});

export default RightSidebar;

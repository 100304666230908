import { makeAutoObservable, runInAction } from 'mobx';
import {
  LogRequestDto,
  LogRequestService,
  LogRequestState,
  LogRequestType,
  ProcessTransferAction,
} from 'api';
import { UserStore } from './UserStore';

export class LogRequestsStore {
  constructor(private userStore: UserStore) {
    makeAutoObservable(this);
  }

  private _isLogRequestsLoading = false;
  private _logRequestsNewData: LogRequestDto[] = [];
  private _logRequestsProcessedData: LogRequestDto[] = [];
  private _selectedRequestsData?: LogRequestDto;
  private _logRequestsRetailerCountNew = 0;
  private _logRequestsBuyerCountNew = 0;
  private _selectedLogItemCode?: string;
  private _isRegisteringNewModel: boolean = false;

  get isLogRequestsLoading() {
    return this._isLogRequestsLoading;
  }

  get selectedLogItemCode() {
    return this._selectedLogItemCode;
  }

  get logRequestsNewData() {
    return this._logRequestsNewData;
  }

  get logRequestsProcessedData() {
    return this._logRequestsProcessedData;
  }

  get selectedRequestsData() {
    return this._selectedRequestsData;
  }

  set selectedRequestsData(logRequestsData: LogRequestDto | undefined) {
    this._selectedRequestsData = logRequestsData;
  }

  get logRequestsRetailerCountNew() {
    return this._logRequestsRetailerCountNew;
  }

  get logRequestsBuyerCountNew() {
    return this._logRequestsBuyerCountNew;
  }

  get isRegisteringNewModel() {
    return this._isRegisteringNewModel;
  }

  setIsRegisteringNewModel(value: boolean) {
    runInAction(() => {
      this._isRegisteringNewModel = value;
    });
  }

  setLogItemCodeToSelected(code: string | undefined) {
    runInAction(() => {
      this._selectedLogItemCode = code;
    });
  }

  async getLogRequests({
    requestType,
    retailerId,
    buyerUserId,
    limit,
    offset,
  }: {
    requestType: LogRequestType;
    retailerId?: number | undefined;
    buyerUserId?: number | undefined;
    limit?: number | undefined;
    offset?: number | undefined;
  }) {
    runInAction(() => {
      this._isLogRequestsLoading = true;
    });

    try {
      const [logRequestsNew, logRequestsProcessed] = await Promise.all([
        LogRequestService.listLogRequests(
          requestType,
          `${LogRequestState.SUBMITTED},${LogRequestState.UNDER_REVIEW}`,
          retailerId,
          buyerUserId,
          limit,
          offset
        ),
        LogRequestService.listLogRequests(
          requestType,
          LogRequestState.APPROVED,
          retailerId,
          buyerUserId,
          limit,
          offset
        ),
      ]);

      runInAction(() => {
        this._logRequestsNewData = logRequestsNew.items;
        this._logRequestsProcessedData = logRequestsProcessed.items;
      });
    } catch (e) {
      console.error('LogRequestsStore getLogRequests', e);
    } finally {
      runInAction(() => {
        this._isLogRequestsLoading = false;
      });
    }
  }

  async getOwnershipLogRequests({
    limit,
    offset,
  }: {
    buyerUserId?: number | undefined;
    limit?: number | undefined;
    offset?: number | undefined;
  }) {
    runInAction(() => {
      this._isLogRequestsLoading = true;
    });

    try {
      const logRequestsNew = await LogRequestService.listLogRequests(
        LogRequestType.TRANSFER,
        LogRequestState.SUBMITTED,
        undefined,
        undefined,
        this.userStore.userData?.id,
        limit,
        offset
      );

      runInAction(() => {
        this._logRequestsNewData = logRequestsNew.items;
      });
    } catch (e) {
      console.error('LogRequestsStore getLogRequests', e);
    } finally {
      runInAction(() => {
        this._isLogRequestsLoading = false;
      });
    }
  }

  async transferItem(code: string) {
    runInAction(() => {
      this._isLogRequestsLoading = true;
    });

    try {
      await LogRequestService.createTransferLogRequest(
        code,
        this.userStore.userData!.id
      );
    } catch (e) {
      console.error('LogRequestsStore transferItem', e);
    } finally {
      runInAction(() => {
        this._isLogRequestsLoading = false;
      });
    }
  }

  async processTransferLogRequest(
    requestId: number,
    action: ProcessTransferAction
  ) {
    runInAction(() => {
      this._isLogRequestsLoading = true;
    });

    try {
      await LogRequestService.processTransferLogRequest(requestId, action);
      await this.getOwnershipLogRequests({});
    } catch (e) {
      console.error('LogRequestsStore processTransferLogRequest', e);
    } finally {
      runInAction(() => {
        this._isLogRequestsLoading = false;
      });
    }
  }
}

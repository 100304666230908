import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserService } from 'api';
import AppRoutes from 'routes';
import PageLayout from 'components/page-layout';

const EmailConfirm = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [status, setStatus] = useState<'success' | 'error' | null>(null);

  useEffect(() => {
    if (!search) return;
    const query = new URLSearchParams(search);
    const token = query.get('token');
    if (!token) {
      setStatus('error');
      return;
    }
    UserService.confirmEmail(token)
      .then(() => setStatus('success'))
      .catch(() => setStatus('error'));
  }, [search]);

  useEffect(() => {
    if (status === 'success')
      setTimeout(() => navigate(AppRoutes.index.path), 3000);
  }, [status]);

  return (
    <PageLayout>
      <div className='flex flex-col justify-center items-center h-full text-2xl text-black font-semibold text-center'>
        {!status && <h1>Please, wait...</h1>}
        {status === 'success' && <h1>Your email successfully confirmed!</h1>}
        {status === 'error' && (
          <h1>Something went wrong and we can't confirm email</h1>
        )}
      </div>
    </PageLayout>
  );
};

export default EmailConfirm;

import { ChangeEvent, FormEvent, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useModelsStore } from 'providers/ModelsStoreProvider';
import Button from 'components/button';
import Input from 'components/input';
import { useLayoutStore } from 'providers/LayoutStoreProvider';

const formDefaultValues = {
  title: '',
  collection: '',
  description: '',
  material: '',
  gtin: '',
  imageUrl: '',
};

const AddModelModal = observer(() => {
  const modelsStore = useModelsStore();
  const layoutStore = useLayoutStore();
  const [form, updateForm] = useState(formDefaultValues);

  const formRef = useRef<HTMLFormElement>(null);

  const handleChangeField =
    (fieldName: keyof typeof formDefaultValues) =>
    (event: ChangeEvent<HTMLInputElement>) =>
      updateForm((prevState) => ({
        ...prevState,
        [fieldName]: event.target.value,
      }));

  const isFormValid = useMemo(
    () => formRef?.current?.checkValidity() || false,
    [form, formRef]
  );

  const handleSubmit = (event: FormEvent<HTMLFormElement> | undefined) => {
    event?.preventDefault();
    modelsStore.createModel(form).then(() => layoutStore.hideContentModal());
  };

  return (
    <form
      onSubmit={handleSubmit}
      className='flex flex-col gap-4 justify-center items-center'
      ref={formRef}
    >
      <span className='text-4xl'>Add new Model</span>
      <Input
        label='Name'
        value={form.title}
        onChange={handleChangeField('title')}
        required
      />
      <div className='w-full'>
        <label className='text-secondary'>Description</label>
        <textarea
          rows={2}
          required
          value={form.description}
          className='w-full text-xl p-3 border border-black/10 rounded-xl resize-none'
          onChange={handleChangeField('description') as any}
        />
      </div>
      <Input
        label='Collection'
        value={form.collection}
        onChange={handleChangeField('collection')}
        required
      />
      <Input
        label='Material'
        value={form.material}
        onChange={handleChangeField('material')}
        required
      />
      <Input
        label='GTIN'
        value={form.gtin}
        onChange={handleChangeField('gtin')}
        required
      />
      <Input
        label='Image URL'
        value={form.imageUrl}
        onChange={handleChangeField('imageUrl')}
        required
      />
      <Button
        fullWidth
        type='submit'
        disabled={!isFormValid}
        isLoading={modelsStore.isLoading}
      >
        Create model
      </Button>
    </form>
  );
});

export default AddModelModal;

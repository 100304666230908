/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiResponse } from '../models/ApiResponse';
import type { JwtResponse } from '../models/JwtResponse';
import type { LoginRequest } from '../models/LoginRequest';
import type { RegisterRequest } from '../models/RegisterRequest';
import type { SetPasswordRequest } from '../models/SetPasswordRequest';
import type { UpdateUserDto } from '../models/UpdateUserDto';
import type { UserDto } from '../models/UserDto';
import type { UsersPaginationResponse } from '../models/UsersPaginationResponse';

import type { CancelablePromise } from 'api/util/CancelablePromise'
import { OpenAPI } from 'api/util/OpenAPI'
import { request as __request } from 'api/util/request'

export class UserService {

    /**
     * Confirm email
     * Confirm email
     * @param securityToken Security token for the operation confirmation
     * @returns ApiResponse Successful operation
     * @throws ApiError
     */
    public static confirmEmail(
        securityToken: string,
    ): CancelablePromise<ApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/confirm_email',
            query: {
                'security_token': securityToken,
            },
            errors: {
                400: `Security token invalid or expired`,
                404: `Email not found`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Log in user into a system
     * Log in user into a system
     * @param requestBody User email and password
     * @returns JwtResponse Successful operation
     * @throws ApiError
     */
    public static loginUser(
        requestBody: LoginRequest,
    ): CancelablePromise<JwtResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Email or password are not valid`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Get current logged in user
     * Get current logged in user
     * @returns UserDto Successful operation
     * @throws ApiError
     */
    public static getMyUser(): CancelablePromise<UserDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user/my',
            errors: {
                401: `Access token is missing or invalid`,
                404: `User not found`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Register new user
     * Register new user
     * @param requestBody User email and password
     * @returns ApiResponse Successful operation
     * @throws ApiError
     */
    public static registerUser(
        requestBody: RegisterRequest,
    ): CancelablePromise<ApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/register',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Input data is not valid`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Initialize password reset
     * Initialize password reset
     * @param email User email
     * @returns ApiResponse Successful operation
     * @throws ApiError
     */
    public static requestUserPasswordReset(
        email: string,
    ): CancelablePromise<ApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/request_password_reset',
            query: {
                'email': email,
            },
            errors: {
                400: `Input data is not valid`,
                404: `User not found`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Resend email confirmation if not expired
     * Resend email confirmation if not expired
     * @param email User email
     * @returns ApiResponse Successful operation
     * @throws ApiError
     */
    public static resendEmailConfirmation(
        email: string,
    ): CancelablePromise<ApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/resend_email_confirmation',
            query: {
                'email': email,
            },
            errors: {
                400: `Input data is not valid`,
                404: `Email confirmation not found`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Reset user password
     * Reset user password
     * @param requestBody New password and security token
     * @returns ApiResponse Successful operation
     * @throws ApiError
     */
    public static resetUserPassword(
        requestBody: SetPasswordRequest,
    ): CancelablePromise<ApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/reset_password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Invalid password, invalid or expired security token`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Search buyer users
     * Search buyer users
     *
     * This can only be done by Retailer
     * @param search
     * @param limit
     * @param offset
     * @returns UsersPaginationResponse Successful operation
     * @throws ApiError
     */
    public static searchBuyers(
        search?: string,
        limit?: number,
        offset?: number,
    ): CancelablePromise<UsersPaginationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user/search_buyers',
            query: {
                'search': search,
                'limit': limit,
                'offset': offset,
            },
            errors: {
                401: `Access token is missing or invalid`,
                403: `Do not have permissions to get user`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Reset user password
     * Reset user password
     * @param login User nickname or email
     * @param requestBody Updated user data
     * @returns ApiResponse Successful operation
     * @throws ApiError
     */
    public static updateUser(
        login: string,
        requestBody: UpdateUserDto,
    ): CancelablePromise<ApiResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/user/update',
            query: {
                'login': login,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Input data is not valid`,
                401: `Access token is missing or invalid`,
                403: `Forbidden`,
                404: `User not found`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Get user by id
     * Get user by id
     *
     * This can only be done by admin or authorized user himself
     * @param userId ID of user
     * @returns UserDto Successful operation
     * @throws ApiError
     */
    public static getUserById(
        userId: number,
    ): CancelablePromise<UserDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/user/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                401: `Access token is missing or invalid`,
                403: `Do not have permissions to get user`,
                404: `User not found`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UserRole {
    ADMIN = 'Admin',
    MANUFACTURER = 'Manufacturer',
    RETAILER = 'Retailer',
    BUYER = 'Buyer',
    VALIDATOR = 'Validator',
}

import { FormEvent, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Button from 'components/button';
import Input from 'components/input';
import { useLogItemsStore } from '../../providers/LogItemsStoreProvider';
import { useLayoutStore } from '../../providers/LayoutStoreProvider';

const AddLogItemsModal = observer(() => {
  const logItemsStore = useLogItemsStore();
  const layoutStore = useLayoutStore();
  const [inputValue, changeInputValue] = useState(0);

  const formRef = useRef<HTMLFormElement>(null);

  const isFormValid = useMemo(
    () => formRef?.current?.checkValidity() || false,
    [inputValue, formRef]
  );

  const handleSubmit = (event: FormEvent<HTMLFormElement> | undefined) => {
    event?.preventDefault();
    logItemsStore
      .createLogItemsGroup(inputValue)
      .then(() => layoutStore.hideContentModal());
  };

  return (
    <form
      onSubmit={handleSubmit}
      className='flex flex-col gap-4 justify-center items-center'
      ref={formRef}
    >
      <span className='text-4xl'>Generate codes</span>
      <Input
        label='Amount of items'
        type='number'
        value={inputValue}
        onChange={({ target }) => changeInputValue(Number(target.value))}
        required
      />
      <Button
        type='submit'
        disabled={!isFormValid}
        isLoading={logItemsStore.isCreateLogItemsGroupLoading}
        fullWidth
      >
        Generate
      </Button>
    </form>
  );
});

export default AddLogItemsModal;

import { PropsWithChildren } from 'react';
import { useUserStore } from 'providers/UserStoreProvider';
import LeftSidebar from './components/left-sidebar';
import RightSidebar from './components/right-sidebar';
import MobileHeader from './components/mobile-header';

const PageLayout = ({ children }: PropsWithChildren) => {
  const userStore = useUserStore();
  return (
    <div
      className={`min-h-screen flex flex-col md:flex-row ${
        userStore.isBuyerRole ? 'bg-white' : 'bg-light'
      }`}
    >
      <MobileHeader />
      <LeftSidebar />
      <section className='min-h-screen w-full md:w-3/5 max-w-[1200px] mx-auto z-10'>
        {children}
      </section>
      <RightSidebar />
    </div>
  );
};

export default PageLayout;

import { useLocalObservable } from 'mobx-react-lite';
import { createContext, useContext } from 'react';
import { UserStore } from 'stores/UserStore';

const storeContext = createContext<UserStore>({} as UserStore);

export const ProvideUserData = ({ children }: any) => {
  const store = useLocalObservable(() => new UserStore());

  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
};

export const useUserStore = () => {
  return useContext(storeContext);
};

import { DetailedHTMLProps, InputHTMLAttributes } from 'react';

type InputProps = {
  label?: string;
  widthFull?: boolean;
  wrapperClassName?: string;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const Input = ({
  label,
  widthFull = true,
  wrapperClassName,
  ...domProps
}: InputProps) => {
  return (
    <div
      className={`relative text-lg ${wrapperClassName || ''} ${
        widthFull ? 'w-full' : ''
      }`}
    >
      <input
        {...domProps}
        id={label}
        className='block rounded-xl px-4 pb-1.5 pt-5 w-full bg-light appearance-none peer'
        placeholder=' '
      />
      {label && (
        <label
          htmlFor={label}
          className={
            'absolute text-lg text-secondary duration-300 transform -translate-y-3 scale-75 top-3 ' +
            'z-10 origin-[0] left-4 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 ' +
            'peer-focus:scale-75 peer-focus:-translate-y-3 font-normal select-none'
          }
        >
          {label}
        </label>
      )}
    </div>
  );
};

export default Input;

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LogItemDetailsDto } from '../models/LogItemDetailsDto';
import type { LogItemPaginationResponse } from '../models/LogItemPaginationResponse';

import type { CancelablePromise } from 'api/util/CancelablePromise'
import { OpenAPI } from 'api/util/OpenAPI'
import { request as __request } from 'api/util/request'

export class LogItemService {

    /**
     * List log items
     * List log items
     *
     * This can only be done by Admin
     * or authorized user linked to log item
     * via manufacturer, retailer, buyer
     * @param logItemsGroupId
     * @param productModelId
     * @param buyerUserId
     * @param limit
     * @param offset
     * @returns LogItemPaginationResponse Successful operation
     * @throws ApiError
     */
    public static listLogItems(
        logItemsGroupId?: number,
        productModelId?: number,
        buyerUserId?: number,
        limit?: number,
        offset?: number,
    ): CancelablePromise<LogItemPaginationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/log_item/list',
            query: {
                'logItemsGroupId': logItemsGroupId,
                'productModelId': productModelId,
                'buyerUserId': buyerUserId,
                'limit': limit,
                'offset': offset,
            },
            errors: {
                400: `Input data is not valid`,
                401: `Request not authorized`,
                403: `Forbidden`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Returns csv with list of log items
     * Returns csv with list of log items
     *
     * This can only be done by Admin
     * or authorized user linked to log item
     * via manufacturer
     * @param logItemsGroupId
     * @param productModelId
     * @returns any Successful operation
     * @throws ApiError
     */
    public static downloadLogItemsCsv(
        logItemsGroupId?: number,
        productModelId?: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/log_item/list/csv',
            query: {
                'logItemsGroupId': logItemsGroupId,
                'productModelId': productModelId,
            },
            errors: {
                400: `Input data is not valid`,
                401: `Request not authorized`,
                403: `Forbidden`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Get log item by code
     * Get log item by code
     * @param code Unique code of log item
     * @returns LogItemDetailsDto Successful operation
     * @throws ApiError
     */
    public static getLogItemByCode(
        code: string,
    ): CancelablePromise<LogItemDetailsDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/log_item/{code}',
            path: {
                'code': code,
            },
            errors: {
                400: `Input data is not valid`,
                404: `Not found`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateLogItemsGroupRequest } from '../models/CreateLogItemsGroupRequest';
import type { CreateUpdateLogItemsGroupDto } from '../models/CreateUpdateLogItemsGroupDto';
import type { LogItemsGroupDto } from '../models/LogItemsGroupDto';
import type { LogItemsGroupPaginationResponse } from '../models/LogItemsGroupPaginationResponse';

import type { CancelablePromise } from 'api/util/CancelablePromise'
import { OpenAPI } from 'api/util/OpenAPI'
import { request as __request } from 'api/util/request'

export class LogItemsGroupService {

    /**
     * List log items
     * List log items
     *
     * This can only be done by Admin
     * or authorized user linked to log item
     * via manufacturer, retailer, buyer
     * @param manufacturerId
     * @param limit
     * @param offset
     * @returns LogItemsGroupPaginationResponse Successful operation
     * @throws ApiError
     */
    public static listLogItemsGroups(
        manufacturerId: number,
        limit?: number,
        offset?: number,
    ): CancelablePromise<LogItemsGroupPaginationResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/log_items_group/list',
            query: {
                'manufacturerId': manufacturerId,
                'limit': limit,
                'offset': offset,
            },
            errors: {
                400: `Input data is not valid`,
                401: `Request not authorized`,
                403: `Forbidden`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Create log_items group
     * Create log_items group
     *
     * This can only be done by Admin
     * or authorized user linked to manufacturer
     * @param manufacturerId ID of manufacturer
     * @param requestBody
     * @returns LogItemsGroupDto Successful operation
     * @throws ApiError
     */
    public static createLogItemsGroup(
        manufacturerId: number,
        requestBody: CreateLogItemsGroupRequest,
    ): CancelablePromise<LogItemsGroupDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/log_items_group/manufacturer/{manufacturer_id}',
            path: {
                'manufacturer_id': manufacturerId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Input data is not valid`,
                401: `Request not authorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Get log items group by id
     * Get log items group by id
     *
     * This can only be done by Admin
     * or authorized user linked to manufacturer
     * @param id ID of log items group
     * @returns LogItemsGroupDto Successful operation
     * @throws ApiError
     */
    public static getLogItemsGroupById(
        id: number,
    ): CancelablePromise<LogItemsGroupDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/log_items_group/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Request not authorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

    /**
     * Update log items group
     * Update log items group
     *
     * This can only be done by Admin
     * or authorized user linked to manufacturer
     * @param id ID of log items group
     * @param requestBody Log items group data
     * @returns LogItemsGroupDto Successful operation
     * @throws ApiError
     */
    public static updateLogItemsGroup(
        id: number,
        requestBody: CreateUpdateLogItemsGroupDto,
    ): CancelablePromise<LogItemsGroupDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/v1/log_items_group/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Request not authorized`,
                403: `Forbidden`,
                404: `Not found`,
                500: `Unhandled Internal Application Error`,
            },
        });
    }

}

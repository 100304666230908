import { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Navigate } from 'react-router-dom';
import AppRoutes from 'routes';
import { useUserStore } from 'providers/UserStoreProvider';
import { ReactComponent as LogoSvg } from 'assets/trustlog-logo.svg';
import { ReactComponent as FMLogoSvg } from 'assets/frank-muller-logo.svg';
import Login from './components/login';
import Registration from './components/registration';

export const AuthPage = observer(({ isBuyer }: { isBuyer?: boolean }) => {
  const [isRegistration, setIsRegistration] = useState(false);
  const userStore = useUserStore();

  const renderForm = useMemo(() => {
    if (!isRegistration) return <Login isBuyer={isBuyer} />;
    else return <Registration isBuyer={isBuyer} />;
  }, [isRegistration]);

  useEffect(() => {
    userStore.authUser();
  }, [userStore.authToken]);

  if (userStore.userData) {
    if (isBuyer && userStore.isBuyerRole)
      return <Navigate to={AppRoutes.index.path} replace />;
    if (!isBuyer && !userStore.isBuyerRole)
      return <Navigate to={AppRoutes.management.path} replace />;
    userStore.logoutUser();
    return null;
  }

  return (
    <div
      className={`flex flex-col relative justify-between items-center h-screen bg-white px-6 ${
        isBuyer
          ? '[&_*]:rounded-none [&_input]:bg-white [&_input]:border [&_input]:border-stroke-light'
          : ''
      }`}
    >
      {!isBuyer && <LogoSvg className='mt-12' />}
      <div className='w-full max-w-[480px]'>
        {isBuyer && <FMLogoSvg className='mx-auto my-8' />}
        <div className='flex justify-between mb-8 [&>*]:w-1/2 [&>*]:p-2 [&>*]:border-b [&>*]:border-b-light'>
          <button
            className={
              !isRegistration
                ? `${
                    isBuyer ? '!border-b-black' : '!border-b-blue'
                  } !border-b-2`
                : 'text-secondary'
            }
            onClick={() => setIsRegistration(false)}
          >
            Sign in
          </button>
          <button
            className={
              isRegistration
                ? `${
                    isBuyer ? '!border-b-black' : '!border-b-blue'
                  } !border-b-2`
                : 'text-secondary'
            }
            onClick={() => setIsRegistration(true)}
          >
            Sign up
          </button>
        </div>
        {renderForm}
      </div>
      {!isBuyer && <LogoSvg className='mt-12 h-[1px] opacity-0' />}
    </div>
  );
});

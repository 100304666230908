import { FormEvent, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useUserStore } from 'providers/UserStoreProvider';
import Button from 'components/button';
import Input from 'components/input';

const Login = observer(({ isBuyer }: { isBuyer?: boolean }) => {
  const userStore = useUserStore();
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('P@ssw0rd');
  const formRef = useRef<HTMLFormElement>(null);

  const isFormValid = useMemo(
    () => formRef?.current?.checkValidity() || false,
    [login, password, formRef]
  );

  const handleSubmit = (event: FormEvent<HTMLFormElement> | undefined) => {
    event?.preventDefault();
    if (isFormValid && !userStore.isLoading)
      userStore.authUser({ login, password });
  };

  return (
    <form ref={formRef} onSubmit={handleSubmit} className='space-y-3'>
      <Input
        required
        type='email'
        label='E-mail'
        value={login}
        onChange={({ currentTarget }) => setLogin(currentTarget.value.trim())}
      />
      <Input
        required
        type='password'
        label='Password'
        value={password}
        onChange={({ currentTarget }) =>
          setPassword(currentTarget.value.trim())
        }
      />
      <Button
        type='submit'
        className='!mt-8 !text-lg'
        isLoading={userStore.isLoading}
        fullWidth
        isBuyer={isBuyer}
      >
        Sign in
      </Button>
    </form>
  );
});

export default Login;

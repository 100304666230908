import { makeAutoObservable, runInAction } from 'mobx';
import {
  CreateUpdateProductModelDto,
  ProductModelDto,
  ProductModelService,
} from 'api';
import { UserStore } from './UserStore';

export class ModelsStore {
  constructor(private userStore: UserStore) {
    makeAutoObservable(this);
  }

  private _isLoading = false;
  private _modelsData: ProductModelDto[] = [];

  get isLoading() {
    return this._isLoading;
  }

  get modelsData() {
    return this._modelsData;
  }

  async getModels(search?: string, limit = 100, offset = 0) {
    runInAction(() => {
      this._isLoading = true;
    });

    try {
      const { items } = await ProductModelService.listProductModels(
        this.userStore.manufacturerData?.id,
        this.userStore.retailerData?.id,
        search,
        limit,
        offset
      );
      runInAction(() => {
        this._modelsData = items;
      });
    } catch (e) {
      console.error('ModelsStore getModels', e);
    } finally {
      runInAction(() => {
        this._isLoading = false;
      });
    }
  }

  async createModel(newProductModel: CreateUpdateProductModelDto) {
    if (!this.userStore.manufacturerData?.id) return;

    runInAction(() => {
      this._isLoading = true;
    });

    try {
      await ProductModelService.createProductModel(
        this.userStore.manufacturerData.id,
        newProductModel
      );
      await this.getModels();
    } catch (e) {
      console.error('ModelsStore addModel', e);
    } finally {
      runInAction(() => {
        this._isLoading = false;
      });
    }
  }
}

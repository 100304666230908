import React, { Suspense } from 'react';
import { observer } from 'mobx-react-lite';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import AppRoutes from 'routes';
import { UserRole } from 'api';
import { AuthPage } from 'modules/auth';
import { ProvideLayout } from 'providers/LayoutStoreProvider';
import { ProvideStaticData } from 'providers/StaticDataProvider';
import { ProvideModelsStore } from 'providers/ModelsStoreProvider';
import { ProvideLogItemsStore } from 'providers/LogItemsStoreProvider';
import { ProvideUserData, useUserStore } from 'providers/UserStoreProvider';
import { ProvideLogRequestsStore } from 'providers/LogRequestsStoreProvider';
import EmailConfirm from 'modules/email-confirm/email-confirm';

const PublicPage = React.lazy(() => import('modules/public'));
const StaffMainPage = React.lazy(() => import('modules/staff-main'));
const OwnerMainPage = React.lazy(() => import('modules/owner-main'));
const RetailerRegisterSalePage = React.lazy(
  () => import('modules/retailer-register-sale')
);

const Providers = ({ children }: { children: any }) => {
  return (
    <ProvideStaticData>
      <BrowserRouter>
        <ProvideUserData>
          <ProvideModelsStore>
            <ProvideLogItemsStore>
              <ProvideLogRequestsStore>
                <ProvideLayout>{children}</ProvideLayout>
              </ProvideLogRequestsStore>
            </ProvideLogItemsStore>
          </ProvideModelsStore>
        </ProvideUserData>
      </BrowserRouter>
    </ProvideStaticData>
  );
};

const StaffProtectedRoute = observer(() => {
  const userStore = useUserStore();
  if (!userStore.userData || userStore.isBuyerRole) {
    return <AuthPage />;
  }
  if (userStore.userData.role === UserRole.RETAILER) {
    return <RetailerRegisterSalePage />;
  }
  return <StaffMainPage />;
});

const OwnerProtectedRoute = observer(() => {
  const userStore = useUserStore();
  if (!userStore.isBuyerRole) {
    return <AuthPage isBuyer />;
  }
  return <OwnerMainPage />;
});

const App = () => {
  return (
    <Providers>
      <Routes>
        <Route path={AppRoutes.emailConfirm.path} element={<EmailConfirm />} />

        <Route
          index
          element={
            <Suspense>
              <OwnerProtectedRoute />
            </Suspense>
          }
        />

        <Route
          path={AppRoutes.management.path}
          element={
            <Suspense>
              <StaffProtectedRoute />
            </Suspense>
          }
        />

        <Route
          path={AppRoutes.public.path}
          element={
            <Suspense>
              <PublicPage />
            </Suspense>
          }
        />

        <Route path='*' element={<Navigate to={AppRoutes.index.path} />} />
      </Routes>
    </Providers>
  );
};

export default App;

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum LogRequestState {
    NEW = 'New',
    SUBMITTED = 'Submitted',
    UNDER_REVIEW = 'UnderReview',
    REJECTED = 'Rejected',
    APPROVED = 'Approved',
}

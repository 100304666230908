import { useLocalObservable } from 'mobx-react-lite';
import { createContext, useContext } from 'react';
import { ModelsStore } from '../stores/ModelsStore';
import { useUserStore } from './UserStoreProvider';

const storeContext = createContext<ModelsStore>({} as ModelsStore);

export const ProvideModelsStore = ({ children }: any) => {
  const userStore = useUserStore();
  const store = useLocalObservable(() => new ModelsStore(userStore));

  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
};

export const useModelsStore = () => {
  return useContext(storeContext);
};

import { observer } from 'mobx-react-lite';
import { useUserStore } from 'providers/UserStoreProvider';
import { ReactComponent as LogoSvg } from 'assets/trustlog-logo.svg';
import { ReactComponent as FMLogoSvg } from 'assets/frank-muller-logo.svg';

const LeftSidebar = observer(() => {
  const userStore = useUserStore();
  const isShowFMLogo = !userStore.userData || userStore.isBuyerRole;
  return (
    <aside className='hidden md:flex flex-col justify-between fixed h-screen p-8 w-full max-w-[275px]'>
      {isShowFMLogo ? <FMLogoSvg /> : <LogoSvg />}
      {userStore.userData?.nickname && (
        <span
          className={` ${
            userStore.isBuyerRole
              ? 'text-black text-lg uppercase'
              : 'text-secondary text-2xl'
          }`}
        >
          {userStore.userData?.nickname}
        </span>
      )}
    </aside>
  );
});

export default LeftSidebar;
